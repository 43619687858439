import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import smallLogo from '../images/logo-full-light.png'; 
import LoginForm from 'components/LoginForm';
import '../css/loginPage.css';

export default function Login() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'Login');
	const theme = useTheme();

	componentTracking();

	return (
		<Box className="boxStyle">
		  <Paper elevation={6} className="paperStyle">
	  
			<Box className="logoContainer">
			  <img src={theme.palette.mode === 'light' ? smallLogo : smallLogo} alt="Logo" className="logoStyle" />
			</Box>
	  
			<Box className="contentContainer">
			  <Typography variant="h5" className="headingStyle">
			  Welcome to Risk Safe
			  </Typography>
			  <Typography className="headingStyle">
			  Please sign in to access your risk registers
			  </Typography>
			  <LoginForm />
			</Box>
	  
		  </Paper>
		</Box>
	  );
}