import { useEffect, useState } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import {
	IApiRiskRegisterLifecycleStatusCreateOrUpdate,
	IApiRiskRegisterTypeWithLifecycleStatuses,
	IApiRiskRegisterTypeCreate,
	IApiRiskRegisterTypeUpdate,
} from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Add, Close, Delete, Edit, NotInterested, Restore, Save, Undo } from '@mui/icons-material';

import useAlerts from 'hooks/useAlerts';
import * as RiskRegisterTypesApi from 'api/riskRegisterTypes';
import ConfirmDialog from './ConfirmDialog';
import AddOrUpdateLifecycleStatusDialog from './AddOrUpdateLifecycleStatusDialog';

interface IRiskRegisterTypeFormProps {
	item?: IApiRiskRegisterTypeWithLifecycleStatuses;
	onSave: () => void;
	onCancel?: () => void;
	disabled?: boolean;
	canDelete?: boolean;
}

interface IFormData {
	name: string;
	description: string;
	lifecycle_statuses: IApiRiskRegisterLifecycleStatusCreateOrUpdate[];
}

const validationSchema = yup.object({
	name: yup.string().max(45, 'The name must be less than 45 characters').required(),
	description: yup.string().max(255, 'The description must be less than 45 characters').required(),
	lifecycle_statuses: yup.array(),
});

export default function RiskRegisterTypeForm({
	item,
	onSave,
	onCancel,
	disabled,
	canDelete,
}: IRiskRegisterTypeFormProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const [isAddLifecycleStatusDialogOpen, setIsAddLifecycleStatusDialogOpen] = useState(false);
	const [isUpdateLifecycleStatusDialogOpen, setIsUpdateLifecycleStatusDialogOpen] = useState(false);
	const [currentLifecycleStatus, setCurentLifecycleStatus] = useState<{
		index: number;
		item: IApiRiskRegisterLifecycleStatusCreateOrUpdate;
	}>();
	const { addAlert } = useAlerts();
	const formik = useFormik<IFormData>({
		initialValues: {
			name: item?.name || '',
			description: item?.description || '',
			lifecycle_statuses: item?.lifecycle_statuses || [],
		},
		validationSchema,
		onSubmit: async ({ name, description, lifecycle_statuses }) => {
			setIsSaving(true);

			if (item) {
				const data: IApiRiskRegisterTypeUpdate = {
					name,
					description,
					lifecycle_statuses,
				};

				try {
					await RiskRegisterTypesApi.updateRegisterType(item.risk_register_type_id, data);
					addAlert('success', `Register type '${data.name}' has been updated`);

					onSave();
				} catch {
					addAlert('error', `Failed to update register type '${data.name}'`);
				}
			} else {
				const data: IApiRiskRegisterTypeCreate = {
					name,
					description,
					lifecycle_statuses,
					disabled: false,
				};

				try {
					await RiskRegisterTypesApi.createRegisterType(data);
					addAlert('success', `Register type '${data.name}' has been added`);

					onSave();
				} catch {
					addAlert('error', `Failed to add register type '${data.name}'`);
				}
			}

			setIsSaving(false);
		},
	});

	useEffect(() => {
		if (item) {
			formik.resetForm({ values: item });
		}
	}, [item]);

	const deleteItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskRegisterTypesApi.deleteRegisterType(item.risk_register_type_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const disableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskRegisterTypesApi.disableRegisterType(item.risk_register_type_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const enableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskRegisterTypesApi.enableRegisterType(item.risk_register_type_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const addLifecycleStatus = (newLifecycleStatus: IApiRiskRegisterLifecycleStatusCreateOrUpdate) => {
		const lifecycleStatuses = formik.values.lifecycle_statuses.slice();
		lifecycleStatuses.push(newLifecycleStatus);
		formik.setFieldValue('lifecycle_statuses', lifecycleStatuses);
	};

	const updateLifecycleStatus = (updatedLifecycleStatus: IApiRiskRegisterLifecycleStatusCreateOrUpdate) => {
		if (!currentLifecycleStatus) {
			return;
		}

		const lifecycleStatuses = formik.values.lifecycle_statuses.slice();
		lifecycleStatuses[currentLifecycleStatus.index] = updatedLifecycleStatus;
		formik.setFieldValue('lifecycle_statuses', lifecycleStatuses);
	};

	const deleteLifecycleStatus = (index: number) => {
		const lifecycleStatuses = [
			...formik.values.lifecycle_statuses.slice(0, index),
			...formik.values.lifecycle_statuses.slice(index + 1),
		];
		formik.setFieldValue('lifecycle_statuses', lifecycleStatuses);
	};

	return (
		<Box>
			<Box>
				<TextField
					label="Risk register type"
					name="name"
					value={formik.values.name}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					disabled={isSaving || disabled}
					fullWidth
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				/>
				<TextField
					label="Description"
					name="description"
					value={formik.values.description}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.description)}
					helperText={formik.touched.description && formik.errors.description}
					disabled={isSaving || disabled}
					fullWidth
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				/>
			</Box>
			<Box sx={{ marginTop: (theme) => theme.spacing(2) }}>
				<Typography variant="h6">Lifecycle statuses</Typography>
				<Typography variant="caption" component="p">
					A list of lifecycle statuses is optional. If at least one lifecycle status is listed below, owners of risk
					registers of this type will need to specify the current lifecycle status of the register.
				</Typography>
				<Typography variant="caption" component="p">
					The lifecycle status also determines the scope of items added to the catalogue.
				</Typography>
				<Box sx={{ display: 'flex' }}>
					<List sx={{ border: (theme) => `0px solid ${theme.palette.divider}` }}>
						{formik.values.lifecycle_statuses.map((lifecycleStatus, index) => (
							<ListItem key={index}>
								<ListItemText primary={lifecycleStatus.name} secondary={lifecycleStatus.description} />
								<IconButton
									size="small"
									disabled={isSaving || disabled}
									onClick={() => {
										setCurentLifecycleStatus({ index, item: lifecycleStatus });
										setIsUpdateLifecycleStatusDialogOpen(true);
									}}
									sx={{ marginLeft: (theme) => theme.spacing(2) }}
								>
									<Edit />
								</IconButton>
								<IconButton size="small" disabled={isSaving || disabled} onClick={() => deleteLifecycleStatus(index)}>
									<Delete />
								</IconButton>
							</ListItem>
						))}
						<ListItem>
							<Button
								size="small"
								disabled={isSaving || disabled}
								startIcon={<Add />}
								variant="outlined"
								onClick={() => setIsAddLifecycleStatusDialogOpen(true)}
							>
								Add...
							</Button>
						</ListItem>
					</List>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', marginTop: (theme) => theme.spacing(2) }}>
				{onCancel && (
					<Button startIcon={<Close />} onClick={() => onCancel()} disabled={isSaving || disabled} variant="outlined">
						Cancel
					</Button>
				)}
				{item && (
					<>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty || disabled}
							variant="outlined"
						>
							Discard changes
						</Button>
						{item.disabled ? (
							<Button
								startIcon={<Restore />}
								onClick={() => enableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Enable
							</Button>
						) : (
							<Button
								startIcon={<NotInterested />}
								onClick={() => disableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Disable
							</Button>
						)}
						<Button
							startIcon={<Delete />}
							onClick={() => setIsDeleteConfirmDialogOpen(true)}
							disabled={isSaving || disabled || !canDelete}
							variant="outlined"
							sx={{ marginLeft: (theme) => theme.spacing(1) }}
						>
							Delete
						</Button>
					</>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					disabled={isSaving || !formik.dirty || !formik.isValid || disabled}
					variant="contained"
				>
					Save
				</Button>
			</Box>
			{isDeleteConfirmDialogOpen && item && (
				<ConfirmDialog
					title="Confirm deletion of register type"
					message={`Are you sure you want to delete the register type '${item.name}'? This action is irreversible`}
					confirmLabel="Delete"
					cancelLabel="Cancel"
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onConfirm={() => deleteItem()}
				/>
			)}
			{isAddLifecycleStatusDialogOpen && (
				<AddOrUpdateLifecycleStatusDialog
					onClose={() => setIsAddLifecycleStatusDialogOpen(false)}
					onSave={addLifecycleStatus}
				/>
			)}
			{isUpdateLifecycleStatusDialogOpen && currentLifecycleStatus && (
				<AddOrUpdateLifecycleStatusDialog
					item={currentLifecycleStatus.item}
					onClose={() => setIsUpdateLifecycleStatusDialogOpen(false)}
					onSave={updateLifecycleStatus}
				/>
			)}
		</Box>
	);
}
