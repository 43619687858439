import { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import logo from '../images/risksafe-logo.png';
import * as UserApi from '../api/user';
import useAlerts from 'hooks/useAlerts';

export default function TermsOfUse({ onTermsAccepted }: { onTermsAccepted: () => void }) {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'TermsofUse');
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();

	useEffect(() => {
		setTimeout(() => setButtonEnabled(true), 3000);
	});

	const acceptTerms = async () => {
		try {
			setIsSaving(true);
			await UserApi.acceptTerms();
			onTermsAccepted();
		} catch {
			addAlert('error', 'Failed to complete, please try again later');
		} finally {
			setIsSaving(false);
		}
	};

	componentTracking();

	return (
		<Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Paper
				elevation={3}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<img style={{ height: '200px' }} src={logo} alt="Logo" />
				<Box sx={{ maxWidth: '800px', maxHeight: '400px', overflowY: 'scroll', padding: (theme) => theme.spacing(2) }}>
					<Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
						Welcome to Risk Safe.
					</Typography>
					<Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
						Before you use the application, please read and accept the Terms of Use below.
					</Typography>
					<Typography variant="h6">Terms of Use (“Terms”)</Typography>
					<Typography>
						This agreement applies between you, the invited user of Risk Safe and Mitie Group plc, the owners of all
						documentation and materials hosted within Risk Safe.
					</Typography>
					<ol>
						<Typography component="li">
							I have been invited to Risk Safe for the purposes of documenting risk analysis.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
							I am at least 18 years of age or older and competent to agree these Terms.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
							Mitie Limited (“Mitie”) may revise these Terms or any additional terms and conditions that are relevant to the Risk Safe service from time to time to reflect change in law or the Risk Safe service.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						By accepting these Terms you have granted me a limited on-exclusive non-transferrable licence to access and use Risk Safe for my internal business purposes and only as permitted by these terms
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I will not 
						</Typography>
						<ul>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							engage in any activity or transmit any information that is illegal, violates any law or regulation, advocates an illegal activity or discusses illegal activities with the intent to commit them;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							interfere with any other party’s use and enjoyment of Risk Safe;
							</Typography>	
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							attempt to impersonate another person or entity;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							access or use the account of another users without permission
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							distribute computer viruses or other code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or electronic communications equipment;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							improperly solicit personal or sensitive information from other users including without limitation address, financial information, or passwords;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							decompile, reverse engineers, disassemble, or otherwise attempt to derive source code from the Risk Safe site, except as expressly permitted in these terms or by law, unless and then only to the extent permitted by applicable law without consent;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							use the services for benchmarking or to compile information for a product or service;
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							access or use the Risk Safe service or documentation for the purpose of developing or operating products or services intended to be offered to third parties in competition with the Risk Safe or allow access by a direct competitor of Risk Safe and/or Mitie; or attempt any of the above
							</Typography>
						</ul>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						All the documentation and information hosted within Risk Safe is private and confidential.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						Mitie makes no warranty or representation that the documentation available for access in Risk Safe contains all necessary information or that all information provided will be accurate.  The Risk Safe services, documentation and site are provided “as is” and my use of the same is at my sole risk.  Mitie makes no representation or warranty of any kind whether express, implied or statutory as to any matter whatsoever and expressly disclaims all implied warranties of merchantability, fitness for a particular purpose, quality, accuracy and title and does not warranty that the Risk Safe services documentation or site will be error-free, will meet your requirement or be timely or secure. 
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						Risk Safe contains materials that are proprietary and are protected by copyright laws, trademarks and other intellectual property laws and is protected as a collective works or compilation under copyright and other laws.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I shall not share, reproduce, copy, distribute, store or in any other fashion re-use any of the documents or information unless otherwise indicated in Risk Safe or unless given express written permission to do so by Mitie Limited.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I agree that I shall not attempt to access areas of Risk Safe that are intentionally restricted by security policies or employ any software or hardware applications that may aid any such unauthorised behaviour.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I agree not to share my username and password and I shall take all reasonable precautions to safeguard my user details. I also agree to not save user account information in plain text on any shared computer or within notes in the vicinity of my computer
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I understand that the files downloaded from Risk Safe may contain formulas, macros, external links, and the like, which may adversely affect my computer.
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						I warrant on behalf of my organisation that the collection and use of any personal information and data provided while using Risk Safe complies with all applicable data protection laws, rules and regulations.  I understand that the information I provide to Mitie or that Mitie collect will be used and protected as described in the Mitie Privacy Policy. 
						</Typography>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						If I am registered for a free trial or other type of limited offer for use then I should note that:
						</Typography>
						<ul>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							The version of Risk Safe that is available for a free trial may not include or allow access to all features and functions
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							Any data that I enter into Risk Safe and any configuration made by or for me during the trial period will be permanently lost at the end of the free trial period unless I purchase a subscription plan for Risk Safe or I export the data before the end of the free trial period
							</Typography>	
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							During a free trial period the Risk Safe service is provided on a “as is” basis without any warranty and Mitie’s aggregate liability to my use of the free trial is limited to £100
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							I am solely responsible for the data I provide or use in the Risk Safe service and determining the suitability of Risk Safe for my organisation and complying with any laws applicable to the data I provide and my use of Risk Safe services and site
							</Typography>
							<Typography sx={{ display: 'list-item', listStyleType: 'circle' }}>
							I will promptly inform Mitie of any unauthorised use of my Risk Safe account of which I become aware.
							</Typography>
						</ul>
						<Typography sx={{ display: 'list-item', listStyleType: 'decimal' }}>
						These Terms will be governed, construed, and interpreted in accordance with the laws of England and Wales and the Courts of England and Wales will have exclusive jurisdiction to determine any dispute arising out of or in connection with these Terms and their interpretation.
						</Typography>
					</ol>
					<Typography>
						Your agreement to comply with and be bound by these Terms is deemed to occur upon you accessing Risk Safe.
						If you do not agree to be bound by these Terms, you should cease all access to Risk Safe and inform&nbsp;
						<a href="mailto:Risk.Management@mitie.com">Risk.Management@mitie.com</a> immediately.
					</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: (theme) => theme.spacing(2),
						}}
					>
						<Button variant="contained" onClick={acceptTerms} disabled={!buttonEnabled || isSaving}>
							I accept
						</Button>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
}
